import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import * as style from './DealContentHeader.module.less';
import Dropdown from 'react-bootstrap/Dropdown/';
import { Flex } from '../../UI/Flex/Flex';
import {
    Highlighter,
    Menu,
    MenuItem,
    Typeahead
} from 'react-bootstrap-typeahead';
import { Fragment, useEffect, useState } from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import industry from '../../../model/industry_data.json';
import classNames from 'classnames';

export const DealContentHeader = observer(() => {
    const store = useStore();
    const [multiSelections, setMultiSelections] = useState([]);
    const [textSearch, setTextSearch] = useState('');

    const updateSelection = (selected: []) => {
        var updated = [];

        selected.forEach((selection: any) => {
            if (!updated.find((item: any) => item.value == selection.value)) {
                updated.push({
                    label: selection.value,
                    value: selection.value
                });
            }
        });
        setMultiSelections(updated);
    };
    const query = store.query;

    if (!query) {
        return null;
    }

    useEffect(() => {
        store.dealPageIndustrySearch = multiSelections.map(
            selection => selection.value
        );
    }, [multiSelections]);

    function filterBy(option, ticker) {
        return (
            option.label.toLowerCase().indexOf(ticker.text.toLowerCase()) > -1
        );
    }

    const braileSpacing = '⠀  ';
    var options = [];
    industry.forEach(industry => {
        var name = industry.name;
        options.push({ label: name, value: name, industry: name });
        industry.subtypes.forEach(sub_industry => {
            options.push({
                label: braileSpacing + sub_industry.name,
                value: sub_industry.name,
                industry: name
            });
        });
    });

    function groupBy(list, keyGetter) {
        const map = {};
        list.forEach(item => {
            const key = keyGetter(item);
            if (!map.hasOwnProperty(key)) {
                map[key] = [];
            }
            map[key].push(item);
        });

        return map;
    }

    var typeahead_props = {};
    typeahead_props['renderMenu'] = (
        results,
        {
            newSelectionPrefix,
            paginationText,
            renderMenuItemChildren,
            ...menuProps
        },
        state
    ) => {
        let index = 0;
        const list = groupBy(results, a => a.industry);
        const items = Object.keys(list)
            .sort()
            .map(region => {
                var subitems = list[region];
                var header = <Menu.Header>{region}</Menu.Header>;
                if (subitems[0].label == region) {
                    //kind of hackish
                    header = null;
                }

                return (
                    <Fragment key={region}>
                        {index !== 0 && <Menu.Divider />}
                        {header}
                        {subitems.map(i => {
                            var isSelected = multiSelections.find(
                                a => a.value == i.value
                            );
                            var cls = classNames({
                                [style.selected]: isSelected
                            });
                            const item = (
                                //<a aria-label="Consumer/Retail" aria-selected="false" id="industry-search-item-0" role="option" class="dropdown-item" href="#">Consumer/Retail</a>
                                <MenuItem
                                    key={index}
                                    option={i}
                                    position={index}
                                    className={cls}
                                >
                                    <Highlighter search={state.text}>
                                        {i.label}
                                    </Highlighter>
                                </MenuItem>
                            );

                            index += 1;
                            return item;
                        })}
                    </Fragment>
                );
            });

        return <Menu {...menuProps}>{items}</Menu>;
    };

    return (
        <Flex direction="column" className={style.header} justify="end">
            <div className={style.title}>Deals Database</div>
            <Flex direction="row" className={style.searchBar}>
                <input
                    type="text"
                    className={style.search}
                    value={store.dealPageNameSearch}
                    onInput={e => {
                        store.dealPageNameSearch = e.currentTarget.value;
                    }}
                    placeholder="Search by name"
                ></input>
                <div className={style.industrySearch}>
                    <Typeahead
                        {...typeahead_props}
                        id="industry-search"
                        className={style.industrySearch}
                        filterBy={filterBy}
                        options={options}
                        placeholder="Search by Industry"
                        onChange={updateSelection}
                        selected={multiSelections}
                        multiple={true}
                    ></Typeahead>
                </div>
            </Flex>
        </Flex>
    );
});
