@import '../../../utils/global.module.less';
.title {
    font-size: 22px;
    font-family: LustTextMedium;
    font-style: italic;
    border-bottom: 1px dashed #d0d5dd;
    color: #243a5c;
    padding-top: 10px;
    padding-bottom: 10px;
}

.desc {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 16px;
    font-family: Hanken Grotesk;
    color: #101828;
}
