@import '../../utils/global.module.less';

.panel_shadow.panel:before {
    content: '';
    background-color: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 20px;
    .shadow();
}

.panel {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
}

.content {
    padding-left: 8px !important;
    padding-top: 5px !important;
}

.title {
    font-size: 22px;
    font-family: LustTextItalic;
    font-weight: 500;
    padding-left: 4px;
}
