@import '../../../../utils/global.module.less';

.container {
    .dropShadow();

    margin-top: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    background-color: white;
    > div {
        background-color: white;
        color: black;
        &:first-child {
            color: white;
            border-radius: 20px 20px 0 20px;
            background-color: @title-color;
        }
        &:last-child {
            border-radius: 0 20px 20px 0;
        }
    }
}
