import { observable } from 'mobx';

type ChatMessage = {
    isMe: boolean;
    message: string;
    time: Date;
    embedList?: number[];
};

class ChatStore {
    constructor() {}

    @observable accessor chatMessages: ChatMessage[] = [
        {
            isMe: false,
            message: 'Hello, Please ask a question',
            time: new Date(),
            embedList: [5579]
        }
    ];
}

const chatStore = new ChatStore();

export const useChatStore = () => chatStore;
export default chatStore;
