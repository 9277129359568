@import '../../../utils/global.module.less';

.gridContainer {
    --gap: 0px;
    --line-offset: calc(var(--gap) / 2);
    --line-thickness: 2px;
    --line-color: red;

    --border-color: #eaecf0;

    display: grid;
    grid-template-columns: [fieldName] 180px;
    grid-auto-columns: 1fr;
    //grid-auto-rows: 50px;
    overflow: hidden;
    gap: var(--gap);
}

.grid_item {
    filter: none;
    position: relative;
    padding: 8px;
    padding-left: 6px;
    padding-right: 6px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    background-color: white;
}

// .grid__item::before,
// .grid__item::after {
//     content: '';
//     position: absolute;
//     background-color: var(--line-color);
//     z-index: 1;
// }

// .grid__item::after {
//     inline-size: 100vw;
//     block-size: var(--line-thickness);
//     inset-inline-start: 0;
//     //inset-block-start: calc(var(--line-offset) * -1);
//     inset-block-start: 0;
// }

// .grid__item::before {
//     inline-size: var(--line-thickness);
//     block-size: 100vh;
//     inset-block-start: 0;
//     inset-inline-start: 0;
//     //inset-inline-start: calc(var(--line-offset) * -1);
// }

.generate-grid-mixin(20);

.generate-grid-mixin(@n, @i: 1) when (@i =< @n) {
    .grid_col_@{i} {
        grid-column: @i;
        --grid-column: @i;
    }

    .grid_row_@{i} {
        grid-row: @i;
        --grid-row: @i;
    }
    .generate-grid-mixin(@n, (@i + 1));
}

.grid_col_start {
    border-left: 1px solid var(--border-color);
}

.grid_col_end {
    border-right: 1px solid var(--border-color);
}

.disable_round_edges {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.grid_row_last.grid_col_start {
    border-bottom-left-radius: 10px;
}

.grid_row_last.grid_col_end {
    border-bottom-right-radius: 10px;
}

.headerRow {
    justify-content: stretch;
    background-color: var(--border-color);
    color: #667085;
    font-family: Hanken Grotesk;
    align-content: center;
    font-weight: bold;
}
