import { Field } from '@assets/component/Panel/PanelTable/PanelTable';
import * as style from '../PanelElements.module.less';
import { Flex } from '@assets/component/UI/Flex/Flex';
export class RowLabel {
    static label(field: Field) {
        return (
            <Flex direction="column" className={style.rowLabel}>
                <div className="title">{field.display}</div>
                {field.description && (
                    <div className="desc">{field.description}</div>
                )}
            </Flex>
        );
    }

    static percent_label(field: Field) {
        return (
            <Flex direction="column" className={style.rowLabel}>
                <div className="title">
                    <i>{field.display}</i>
                </div>
                {field.description && (
                    <div className="desc">{field.description}</div>
                )}
            </Flex>
        );
    }
}
