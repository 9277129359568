import { observer } from 'mobx-react';
import { Flex } from '../UI/Flex/Flex';
import * as style from './Panel.module.less';
import classNames from 'classnames';

interface PanelProps {
    children?: React.ReactNode;
    className?: string;
    shadow?: boolean;
}

export const Panel = observer((props: PanelProps) => {
    const className = props.className || '';
    const cls = classNames(
        style.panel,
        { [style.panel_shadow]: props.shadow },
        className
    );

    return (
        <Flex className={cls} direction="column">
            {props.children}
        </Flex>
    );
});
