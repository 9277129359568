@import '../../../utils/global.module.less';

.title {
    font-size: 40px;
    font-family: LustTextMedium;
}

.header {
    background-color: white;
    padding-left: @side-padding;
    padding-right: @side-padding;
}

.seperatorFields {
    margin-left: 5px;
    margin-right: 5px;
    transform: translateY(2px);
    color: #d0d5dd !important;
}
