"id","document_id"
5571,8
5572,8
5573,8
5574,8
5575,8
5576,8
5577,8
5578,8
5579,8
5580,8
5581,8
5582,8
5583,8
5584,8
5585,8
5586,8
5587,8
5588,8
5589,8
5590,8
5591,8
5592,8
5593,8
5594,8
5595,8
5596,8
5597,8
5598,8
5599,8
5600,8
5601,8
5602,8
5603,8
5604,8
5605,8
5606,8
5607,8
5608,8
5609,8
5610,8
5611,8
5612,8
5613,8
5614,8
5615,8
5616,8
5617,8
5618,8
5619,8
5620,8
5621,8
5622,8
5623,8
5624,8
5625,8
5626,8
5627,8
5628,8
5629,8
5630,8
5631,8
5632,8
5633,8
5634,8
5635,8
5636,8
5637,8
5638,8
5639,8
5640,8
5641,8
5642,8
5643,8
5644,8
5645,8
5646,8
5647,8
5648,8
5649,8
5650,8
5651,8
5652,8
5653,8
5654,8
5655,8
5656,8
5657,8
5658,8
5659,8
5660,8
5661,8
5662,8
5663,8
5664,8
5665,8
5666,8
5667,8
5668,8
5669,8
5670,8
5671,8
5672,8
5673,8
5674,8
5675,8
5676,8
5677,8
5678,8
5679,8
5680,8
5681,8
5682,8
5683,8
5684,8
5685,8
5686,8
5687,8
5688,8
5689,8
5690,8
5691,8
5692,8
5693,8
5694,8
5695,8
5696,8
5697,8
5698,8
5699,8
5700,8
5701,8
5702,8
5703,8
5704,8
5705,8
5706,8
5707,8
5708,8
5709,8
5710,8
5711,8
5712,8
5713,8
5714,8
5715,8
5716,8
5717,8
5718,8
5719,8
5720,8
5721,8
5722,8
5723,8
5724,8
5725,8
5726,8
5727,8
5728,8
5729,8
5730,8
5731,8
5732,8
5733,8
5734,8
5735,8
5736,8
5737,8
5738,8
5739,8
5740,8
5741,8
5742,8
5743,8
5744,8
5745,8
5746,8
5747,8
5748,8
5749,8
5750,8
5751,8
5752,8
5753,8
5754,8
5755,8
5756,8
5757,8
5758,8
5759,8
5760,8
5761,8
5762,8
5763,8
5764,8
5765,8
5766,8
5767,8
5768,8
5769,8
5770,8
5771,8
5772,8
5773,8
5774,8
5775,8
5776,8
5777,8
5778,8
5779,8
5780,8
5781,8
5782,8
5783,8
5784,8
5785,8
5786,8
5787,8
5788,8
5789,8
5790,8
5791,8
5792,8
5793,8
5794,8
5795,8
5796,8
5797,8
5798,8
5799,8
5800,8
5801,8
5802,8
5803,8
5804,8
5805,8
5806,8
5807,8
5808,8
5809,8
5810,8
5811,8
5812,8
5813,8
5814,8
5815,8
5816,8
5817,8
5818,8
5819,8
5820,8
5821,8
5822,8
5823,8
5824,8
5825,8
5826,8
5827,8
5828,8
5829,8
5830,8
5831,8
5832,8
5833,8
5834,8
5835,8
5836,8
5837,8
5838,8
5839,8
5840,8
5841,8
5842,8
5843,8
5844,8
5845,8
5846,8
5847,8
5848,8
5849,8
5850,8
5851,8
5852,8
5853,8
5854,8
5855,8
5856,8
5857,8
5858,8
5859,8
5860,8
5861,8
5862,8
5863,8
5864,8
5865,8
5866,8
5867,8
5868,8
5869,8
5870,8
5871,8
5872,8
5873,8
5874,8
5875,8
5876,8
5877,8
5878,8
5879,8
5880,8
5881,8
5882,8
5883,8
5884,8
5885,8
5886,8
5887,8
5888,8
5889,8
5890,8
5891,8
5892,8
5893,8
5894,8
5895,8
5896,8
5897,8
5898,8
5899,8
5900,8
5901,8
5902,8
5903,8
5904,8
5905,8
5906,8
5907,8
5908,8
5909,8
5910,8
5911,8
5912,8
5913,8
5914,8
5915,8
5916,8
5917,9
5918,9
5919,9
5920,9
5921,9
5922,9
5923,9
5924,9
5925,9
5926,9
5927,9
5928,9
5929,9
5930,9
5931,9
5932,9
5933,9
5934,9
5935,9
5936,9
5937,9
5938,9
5939,9
5940,9
5941,9
5942,9
5943,9
5944,9
5945,9
5946,9
5947,9
5948,9
5949,9
5950,9
5951,9
5952,9
5953,9
5954,9
5955,9
5956,9
5957,9
5958,9
5959,9
5960,9
5961,9
5962,9
5963,9
5964,9
5965,9
5966,9
5967,9
5968,9
5969,9
5970,9
5971,9
5972,9
5973,9
5974,9
5975,9
5976,9
5977,9
5978,9
5979,9
5980,9
5981,9
5982,9
5983,9
5984,9
5985,9
5986,9
5987,9
5988,9
5989,9
5990,9
5991,9
5992,9
5993,9
5994,9
5995,9
5996,9
5997,9
5998,9
5999,9
6000,9
6001,9
6002,9
6003,9
6004,9
6005,9
6006,9
6007,9
6008,9
6009,9
6010,9
6011,9
6012,9
6013,9
6014,9
6015,9
6016,9
6017,9
6018,9
6019,9
6020,9
6021,9
6022,9
6023,9
6024,9
6025,9
6026,9
6027,9
6028,9
6029,9
6030,9
6031,9
6032,9
6033,9
6034,9
6035,9
6036,9
6037,9
6038,9
6039,9
6040,9
6041,9
6042,9
6043,9
6044,9
6045,9
6046,9
6047,9
6048,9
6049,9
6050,9
6051,9
6052,9
6053,9
6054,9
6055,9
6056,9
6057,9
6058,9
6059,6
6060,6
6061,6
6062,6
6063,6
6064,6
6065,6
6066,6
6067,6
6068,6
6069,6
6070,6
6071,6
6072,6
6073,6
6074,6
6075,7
6076,7
6077,7
6078,7
6079,7
6080,7
6081,7
6082,7
6083,7
6084,7
6085,7
6086,7
6087,7
6088,7
6089,7
6090,7
6091,7
6092,7
6093,7
6094,7
6095,7
6096,7
6097,7
6098,7
6099,7
6100,7
6101,7
6102,7
6103,7
6104,7
6105,7
6106,7
6107,7
6108,7
6109,7
6110,7
6111,7
6112,7
6113,7
6114,7
6115,7
6116,7
6117,7
6118,7
6119,7
6120,7
6121,7
6122,7
6123,7
6124,7
6125,7
6126,7
6127,7
6128,7
6129,7
6130,7
6131,7
6132,7
6133,7
6134,7
6135,7
6136,7
6137,7
6138,7
6139,7
6140,7
6141,7
6142,7
6143,7
6144,7
6145,7
6146,7
6147,7
6148,7
6149,7
6150,7
6151,7
6152,7
6153,7
6154,7
6155,7
6156,7
6157,7
6158,7
6159,7
6160,7
6161,7
6162,7
6163,7
6164,7
6165,7
6166,7
6167,7
6168,7
6169,7
6170,7
6171,7
6172,7
6173,7
6174,7
6175,7
6176,7
6177,7
6178,7
6179,7
6180,7
6181,7
6182,7
6183,7
6184,7
6185,7
6186,7
6187,7
6188,7
6189,7
6190,7
6191,7
6192,7
6193,7
6194,7
6195,7
6196,7
6197,7
6198,7
6199,7
6200,7
6201,7
6202,7
6203,7
6204,7
6205,4
6206,4
6207,4
6208,4
6209,4
6210,4
6211,4
6212,4
6213,4
6214,4
6215,4
6216,4
6217,4
6218,4
6219,4
6220,4
6221,4
6222,4
6223,3
6224,3
6225,3
6226,3
6227,3
6228,3
6229,3
6230,3
6231,3
6232,3
6233,3
6234,3
6235,3
6236,3
6237,3
6238,3
6239,3
6240,3
6241,3
6242,3
6243,3
6244,3
6245,3
6246,3
6247,3
6248,3
6249,3
6250,3
6251,3
6252,3
6253,3
6254,3
6255,3
6256,3
6257,3
6258,3
6259,3
6260,3
6261,3
6262,3
6263,3
6264,3
6265,3
6266,3
6267,3
6268,3
6269,3
6270,3
6271,3
6272,3
6273,3
6274,3
6275,3
6276,3
6277,3
6278,3
6279,3
6280,3
6281,3
6282,3
6283,3
6284,3
6285,3
6286,3
6287,3
6288,3
6289,3
6290,3
6291,3
6292,3
6293,3
6294,3
6295,3
6296,3
6297,3
6298,3
6299,3
6300,3
6301,3
6302,3
6303,3
6304,3
6305,3
6306,3
6307,3
6308,3
6309,3
6310,3
6311,3
6312,3
6313,3
6314,3
6315,3
6316,3
6317,3
6318,3
6319,3
6320,3
6321,3
6322,3
6323,3
6324,3
6325,3
6326,3
6327,3
6328,3
6329,3
6330,3
6331,3
6332,3
6333,3
6334,3
6335,3
6336,3
6337,3
6338,1
6339,1
6340,1
6341,1
6342,1
6343,1
6344,1
6345,1
6346,1
6347,1
6348,1
6349,1
6350,1
6351,1
6352,1
6353,1
6354,1
6355,1
6356,1
6357,1
6358,1
6359,1
6360,1
6361,1
6362,1
6363,1
6364,1
6365,1
6366,1
6367,1
6368,1
6369,1
6370,1
6371,1
6372,1
6373,1
6374,1
6375,1
6376,1
6377,1
6378,1
6379,1
6380,1
6381,1
6382,1
6383,1
6384,1
6385,1
6386,1
6387,1
6388,1
6389,1
6390,1
6391,1
6392,1
6393,1
6394,1
6395,1
6396,1
6397,1
6398,1
6399,1
6400,1
6401,1
6402,1
6403,1
6404,1
6405,1
6406,1
6407,1
6408,1
6409,1
6410,1
6411,1
6412,1
6413,1
6414,1
6415,1
6416,1
6417,1
6418,1
6419,1
6532,2
6533,2
6534,2
6535,2
6536,2
6537,2
6538,2
6539,2
6540,2
6541,2
6542,2
6543,2
6544,2
6545,2
6546,2
6547,2
6548,2
6549,2
6550,2
6551,2
6552,2
6553,2
6554,2
6555,2
6556,2
6557,2
6558,2
6559,2
6560,2
6561,2
6562,2
6563,2
6564,2
6565,2
6566,2
6567,2
6568,2
6569,2
6570,2
6571,2
6572,2
6573,2
6574,2
6575,2
6576,2
6577,2
6578,2
6579,2
6580,2
6581,2
6582,2
6583,2
6584,2
6585,2
6586,2
6587,2
6588,2
6589,2
6590,2
6591,2
6592,2
6593,2
6594,2
6595,2
6596,2
6597,2
6598,2
6599,2
6600,2
6601,2
6602,2
6603,2
6604,2
6605,2
6606,2
6607,2
6608,2
6609,2
6610,2
6611,2
6612,2
6613,2
6614,2
6615,2
6616,2
6617,2
6618,2
6619,2
6620,2
6621,2
6622,2
6623,2
6624,2
6625,2
6626,2
6627,2
6628,2
6629,2
6630,2
6631,2
6632,2
6633,2
6634,2
6635,2
6636,2
6637,2
6638,2
6639,2
6640,2
6641,2
6642,2
6643,2
