import { Center } from '@assets/component/Center/Center';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import * as style from './PanelTable.module.less';
export type Field = {
    source: string;
    display: string;
    description?: string;
};

export type PanelColumn = {
    column: Field;
    element?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    editor?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    header?: (col: PanelColumn) => ReactElement;
    overrideValue?: (field: Field, column: Field) => any;
    dontUseRowFormatting?: boolean;
};

export type PanelRow = {
    field: Field;
    label?: (field: Field) => ReactElement;
    element?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    editor?: (row: PanelRow, col: PanelColumn, data: any) => ReactElement;
    formatter?: (data: any) => any;
    value: (field: Field, column: Field) => any;
    editable?: boolean;
    dontShowSources?: boolean;
};

interface IPanelTableProps {
    columns: PanelColumn[];
    rows: PanelRow[];
    disable_round_edges?: boolean;

    onEditField?: (row: PanelRow, column: PanelColumn, newValue: any) => void;
}

export const PanelTable = (props: IPanelTableProps) => {
    const [editCellId, setEditCellId] = useState('');
    const [editCellValue, setCellEditorValue] = useState('');

    const setValueInTextboxUtil = (value: any) => {
        if (
            value.toString() == 'NaN' ||
            value.toString() == 'N\\A' ||
            value.toString() == 'N/A'
        ) {
            value = '';
        }

        setCellEditorValue(value);
    };
    const disableRoundEdges = props.disable_round_edges === true;

    const elementsToGridRow = (
        row: number,
        elements: ReactElement[],
        classesToAdd: string[] = [],
        rowPosition: 'start' | 'end' | 'none' = 'none'
    ) => {
        return elements.map((el, index) => {
            var cls = classNames(
                style.grid_item,
                style[`grid_row_${row}`],
                style[`grid_col_${index + 1}`],
                ...classesToAdd,
                style.grid__item,
                { [style.grid_col_start]: index === 0 },
                { [style.grid_col_end]: index === elements.length - 1 },
                { [style.grid_row_first]: rowPosition === 'start' },
                { [style.grid_row_last]: rowPosition === 'end' },
                { [style.disable_round_edges]: disableRoundEdges }
            );

            return (
                <div key={'Row_' + row + '_' + index} className={cls}>
                    {el}
                </div>
            );
        });
    };

    const rows = [];

    const headerLabels = [
        <div></div>,
        ...props.columns.map(col => {
            const el = col.header ? (
                col.header(col)
            ) : (
                <div>{col.column.display}</div>
            );

            return <div key={col.column.source}>{el}</div>;
        })
    ];
    rows.push(elementsToGridRow(1, headerLabels, [style.headerRow]));

    props.rows.forEach((row, rowIndex) => {
        const newRow = [
            <Center justifyContent="start">
                {row.label ? row.label(row.field) : row.field.display}
            </Center>
        ];

        props.columns.forEach((col, colIndex) => {
            const isRowEditable = row.editable;
            const columnsInRow = props.columns.length;

            const cellId = 'cell_' + rowIndex + '_' + colIndex;
            const editMode = editCellId === cellId;

            let val = '-';
            let val_noformatting = '-';
            const valFn = col.overrideValue || row.value;
            try {
                val = valFn(row.field, col.column);
                val_noformatting = val;

                if (!editMode) {
                    if (row.formatter && col.dontUseRowFormatting !== true) {
                        val = row.formatter(val);
                    }
                }
            } catch (e) {
                console.log('Error in PanelTable', e);
                val = '-';
            }

            const createEditorFallback = (
                row: PanelRow,
                col: PanelColumn,
                val: any
            ) => {
                return (
                    <input
                        autoFocus={true}
                        type="text"
                        value={val}
                        style={{ width: '100%' }}
                        onKeyDown={e => {
                            if (
                                e.key === 'Enter' ||
                                e.key === 'Tab' ||
                                e.key == 'ArrowUp' ||
                                e.key == 'ArrowDown'
                            ) {
                                e.preventDefault();

                                const val = editCellValue;
                                if (props.onEditField) {
                                    props.onEditField(row, col, val);
                                }

                                setEditCellId('');

                                var nextColumnIndex = colIndex;
                                var nextRowIndex = rowIndex;

                                if (e.shiftKey) {
                                    nextColumnIndex -= 1;
                                    if (nextColumnIndex < 0) {
                                        nextColumnIndex = 0;
                                    }
                                } else if (e.key == 'ArrowUp') {
                                    for (let i = 0; i < rows.length; i++) {
                                        nextRowIndex -= 1;

                                        if (nextRowIndex <= -1) {
                                            return;
                                        }

                                        if (props.rows[nextRowIndex].editable) {
                                            break;
                                        }
                                    }
                                } else if (e.key == 'ArrowDown') {
                                    for (let i = 0; i < rows.length; i++) {
                                        nextRowIndex += 1;
                                        if (nextRowIndex >= props.rows.length) {
                                            return;
                                        }
                                        if (props.rows[nextRowIndex].editable) {
                                            break;
                                        }
                                    }
                                } else if (e.key == 'Tab') {
                                    nextColumnIndex += 1;
                                    if (nextColumnIndex >= columnsInRow) {
                                        nextColumnIndex -= 1;
                                    }
                                } else if (e.key == 'Enter') {
                                    return;
                                }

                                const nextEditId =
                                    'cell_' +
                                    nextRowIndex +
                                    '_' +
                                    nextColumnIndex;
                                setEditCellId(nextEditId);

                                var newRow = props.rows[nextRowIndex];
                                var newCol = props.columns[nextColumnIndex];
                                const valFn =
                                    newCol.overrideValue || newRow.value;
                                let newEditValue = valFn(
                                    newRow.field,
                                    newCol.column
                                );
                                setValueInTextboxUtil(newEditValue);
                            } else if (e.key === 'Escape') {
                                setEditCellId('');
                            } else if (e.key === 'Delete') {
                                setEditCellId('');
                                props.onEditField(row, col, null);
                            }
                        }}
                        onInput={e => {
                            const newValue = (e.target as HTMLInputElement)
                                .value;
                            setValueInTextboxUtil(newValue);
                        }}
                    />
                );
            };

            const createEl = col.element || row.element;
            const createEditor =
                col.editor || row.editor || createEditorFallback;

            const el = editMode ? createEditor : createEl;
            const newEl = (
                <Center
                    key={colIndex}
                    justifyContent="start"
                    onDoubleClick={() => {
                        if (props.onEditField && row.editable) {
                            setValueInTextboxUtil(val_noformatting);
                            setEditCellId(cellId);
                        }
                    }}
                >
                    {editMode
                        ? createEditor(row, col, editCellValue)
                        : el(row, col, val)}
                </Center>
            );
            newRow.push(newEl);
        });

        const rowPosition =
            rowIndex === 0
                ? 'start'
                : rowIndex === props.rows.length - 1
                  ? 'end'
                  : 'none';
        rows.push(elementsToGridRow(rows.length + 1, newRow, [], rowPosition));
    });
    // props.columns.forEach((col, index) => {
    //     const v = col.value(col.field, col.column, props.data);

    //     const row = dataToRow(
    //         index + 2,
    //         data.map((d: any) => d.value)
    //     );
    //     rows.push(row);
    // });
    return <div className={style.gridContainer}>{rows}</div>;
};
