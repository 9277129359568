import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export const ModalContainer = observer(() => {
    const store = useStore();
    const show = store.modal != null;

    const handleClose = () => {
        store.modal = null;
    };

    const title = store.modal?.title ?? '';
    const transcript = store.modal?.transcript ?? null;

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {transcript?.highlight}
                    <br />
                    <br />
                    Source: {transcript?.source_text}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
});

export default ModalContainer;
