.thumb {
    width: 750px;
    height: 400px;
    padding: 30px;
    margin-left: 0;
    margin-right: 20px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    &:hover {
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }
}

.score {
    width: 130px;
    position: absolute;
    top: 30px;
    right: 40px;
}

.overview {
    font-size: 16px;
    font-family: Hanken Grotesk;
}

.title {
    font-size: 24px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.industry {
    font-size: 16px;
    font-family: Hanken Grotesk;
    padding-bottom: 10px;
}

.fieldContainer {
    padding-right: 40px;
}

.fieldTitle {
    font-size: 18px;
    font-family: Hanken Grotesk;
}

.fieldValue {
    font-size: 18px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #e0e0e0;
    margin-bottom: 15px;
}
