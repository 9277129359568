//Get yearly value
//get Value
//default values
//Sources 

import { get_numbers } from '@assets/utils/Utils';
import { Field, PanelColumn, PanelRow } from '../Panel/PanelTable/PanelTable';
import _ from 'lodash';
import { FieldsSchema } from '@assets/model/FieldsSchema';
import assert from 'assert';

export class DataAccessUtils {
	private report: any;
	constructor(reportOriginal: any, private overrides: any) {

		this.report = _.cloneDeep(reportOriginal);
		if (!this.report) {
			return null;
		}

		var over_rides = overrides;
		for (var field in over_rides) {
			const value = over_rides[field];
			//typeof a == 'object' && Array.isArray(a)\
			//Connect to field type in schmea to know sub field
			const fieldsSchema = FieldsSchema;

			if (typeof value == 'object') {
				if (!Array.isArray(value)) {
					var set = value as {};

					if (!Array.isArray(this.report[field])) {
						this.report[field] = [];
					}

					const keys = Object.keys(set);
					keys.forEach(year => {
						const yearValue = set[year];
						assert(
							fieldsSchema.hasOwnProperty(field),
							`Field ${field} not found in schema`
						);
						const subFieldName = fieldsSchema[field].field;

						const e = this.report[field].find(arrYear => {
							return parseInt(arrYear.year) == parseInt(year);
						});

						const entry = {
							year: parseInt(year),
							[subFieldName]: yearValue
						};

						if (!e) {
							this.report[field].push(entry);
						} else {
							e[subFieldName] = yearValue;
						}
					});
				} else {
					//Array TODO
				}
			} else {
				this.report[field] = value;
			}
		}
	}

	isValueEditedByRowCol(row: PanelRow, col: PanelColumn) {
		const schema = FieldsSchema;
		const edits = this.overrides;

		const f = schema[row.field.source];

		if (f.yearly) {
			if (!edits.hasOwnProperty(row.field.source)) {
				return false;
			}
			if (!edits[row.field.source].hasOwnProperty(col.column.source.toString())) {
				return false;
			}

			return true;

		} else { //value
			return edits.hasOwnProperty(row.field.source);
		}
	}

	isValueEditedAtAll(fieldName: string) {
		const edits = this.overrides;
		return edits.hasOwnProperty(fieldName);
	}

	value(fieldName: string) {
		let data = this.report;
		if (!data) return 'N/A';

		if (data.hasOwnProperty(fieldName)) {
			return data[fieldName];
		}

		return 'N/A';
	}


	actualYear() {
		const actualizedYears = this.report['list_years_actualized_revenue'] ?? [];
		return Math.max(...actualizedYears);
	}

	valueByYearDivideByRevenue(fieldName: string, year: number, yearOffset: number = 0) {
		let data = this.report;
		if (!data) {
			return 'N/A';
		}
		try {
			const fieldValue = this.valueByYear(fieldName, year, yearOffset);
			const revenue = this.valueByYear('net_revenue_per_year', year, yearOffset);
			return fieldValue / revenue;
		} catch {
			return 'N/A';
		}
	}

	valueByYear(fieldName: string, year: number, yearOffset: number = 0) {
		let data = this.report;
		if (!data) {
			return 'N/A';
		}

		const fieldInfo = FieldsSchema[fieldName];
		const valueField = fieldInfo.field;

		const yearSource = year + yearOffset;
		const dataRow = data[fieldName];

		if (!dataRow || !Array.isArray(dataRow)) {
			return 'N/A';
		}

		const r = dataRow.find((d: any) => parseInt(d.year) === yearSource);
		if (r == undefined) {
			return 'N/A';
		}

		if (r.hasOwnProperty(valueField)) {
			return r[valueField];
		} else {
			return 'N/A';
		}
	}

	organicCagr(howManYears: number) {
		try {
			const q = this;
			const lastYear = q.actualYear();
			const current = q.valueByYear(
				'net_revenue_per_year',
				lastYear
			);
			const before = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
				-howManYears
			);

			if (current == 'N/A' || before == 'N/A') {
				return 'N/A';
			}
			return (current / before) ** (1 / howManYears) - 1;
		} catch {
			return 'N/A';
		}
	}

	//Merge the two
	projectedCagr(howManYears: number) {
		try {
			const q = this;
			const lastYear = q.actualYear();
			const current = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
			);
			const projected = q.valueByYear(
				'net_revenue_per_year',
				lastYear,
				howManYears
			);

			if (current == 'N/A' || projected == 'N/A') {
				return 'N/A';
			}
			return (projected / current) ** (1 / howManYears) - 1;
		} catch (e) {
			return 'N/A';
		}
	}

	sourcesByField(fieldName: string,) {
		let fields = [];

		if (fieldName === 'recurring_revenue_summary') {
			fields = [
				'subscription_revenue',
				'recurring_revenue_stream',
				'contractual_revenue'
			];
		} else {
			fields.push(fieldName);
		}

		const conv_source_to_num_array = (sourcesValue: any) => {
			var pages = get_numbers(sourcesValue.toString());
			return pages;
		};

		var sources = [];
		fields.forEach(fieldEntry => {
			let val = this.value(fieldEntry + '_page_source');
			sources = sources.concat(
				conv_source_to_num_array(val)
			);
		});

		return sources;
	}

	sources(field: Field, column: Field) {
		let l = this.sourcesByField(field.source);
		l = l.sort((a, b) => a - b);
		return _.uniq(l)
	}
}