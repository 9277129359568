import logo from '@assets/component/Header/logo.svg';
import { useNavUtils } from '@assets/utils/NavUtils';

export const Logo = () => {
    const nav = useNavUtils();
    return (
        <img
            style={{ cursor: 'pointer' }}
            src={logo}
            width={220}
            onClick={() => {
                nav.home();
            }}
        />
    );
};
