interface ICenterProps {
    children: React.ReactNode;
    alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline';
    justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around';
    width?: string;
    className?: string;
    onClick?: () => void;
    onDoubleClick?: () => void;
}

export const Center = (props: ICenterProps) => {
    const align = props.alignItems || 'center';
    const justify = props.justifyContent || 'center';
    const width = props.width || '100%';
    const cls = props.className || '';
    return (
        <div
            className={cls}
            style={{
                display: 'flex',
                position: 'relative',
                alignItems: align,
                width: '100%',
                height: '100%',
                justifyContent: justify
            }}
            onClick={() => {
                props.onClick && props.onClick();
            }}
            onDoubleClick={() => {
                props.onDoubleClick && props.onDoubleClick();
            }}
        >
            {props.children}
        </div>
    );
};
