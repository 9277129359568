import { TickerSearch } from '../TickerSearch/TickerSearch';
import { Flex } from '../UI/Flex/Flex';
import * as style from './Header.module.less';
import { Logo } from './Logo';

interface IHeader {
    disableTicker?: boolean;
}
export const Header = (p: IHeader) => {
    return (
        <Flex className={style.container}>
            <Logo />
            <div className={'flex-grow-1'} />
            {p.disableTicker !== true && <TickerSearch />}
        </Flex>
    );
};
