import { Backend } from '@assets/backend/backend';
import { DataAccessUtils } from '@assets/component/Financial/DataAccessUtils';
import { assert } from 'console';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { autorun, computed, has, observable, runInAction, values } from 'mobx';
import moment from 'moment';
import { FieldsSchema } from './FieldsSchema';
import _ from 'lodash';

function isString(s) {
    return typeof s === 'string' || s instanceof String;
}

interface StockInfo {
    ticker: string;
    current: number;
    eps: number;
    avg_over_year: number;
}

interface TranscriptEntry {
    highlight: string;
    source_text: string;
}
interface KPI {
    kpi: string;
    value: string;
}

interface YearlyFinance {
    cogs: string;
    ebitda: string;
    revenue: string;
    year: string;
    ebitda_margin: string;
    yoy_growth: string;
}
interface KeyPerson {
    name: string;
    position: string;
    started_job_year: string;
    bio: string;
}

interface Segment {
    revenue: string;
    service: string;
    segment: string;
    share: string;
    short_description: string;
}

interface ActiveJob {
    ticker: string;
    status: string;
    task: string;
}

interface ModalDisplay {
    title: string;
    transcript: TranscriptEntry;
}

type ImageLookup = { [key: number]: string };

const list = [];
function preloadImages(array) {
    for (var i = 0; i < array.length; i++) {
        var img = new Image();
        img.onload = function () {
            var index = list.indexOf(this);
            if (index !== -1) {
                // remove image from the array once it's loaded
                // for memory consumption reasons
                list.splice(index, 1);
            }
        };
        list.push(img);
        img.src = array[i];
    }
}

class Store {
    constructor() {
        // autorun(async () => {
        //     if (!this.activeReport) {
        //         return null;
        //     }
        //     var el = document.getElementById('rootContainer');
        //     el.style.width = '2px';
        //     const baseURL = 'pdf_images/';
        //     const companyName = this.selectedTickerId;
        //     const storage = getStorage();
        //     // `${baseURL}${companyName}/hd_page-${this.pdfImagePage}.png`
        //     //get all the images used in sources
        //     var data = this.activeReport;
        //     var max_pages = this.activeReport.total_pdf_pages;
        //     var pagesToFetch = { '1': true };
        //     for (var i = 1; i <= max_pages; i++) {
        //         pagesToFetch[i] = true;
        //     }
        //     if (this.imageLookup.hasOwnProperty(companyName)) {
        //         return;
        //     }
        //     this.imageLookup[companyName] = {};
        //     // Object.keys(data).forEach(key => {
        //     //     if (key.endsWith('_page_source')) {
        //     //         const value = data[key];
        //     //         const pages = get_numbers(value);
        //     //         pages.forEach(page => {
        //     //             pagesToFetch[page] = true;
        //     //         });
        //     //     }
        //     // });
        //     var current = this.imageLookup[companyName];
        //     for (var page in pagesToFetch) {
        //         const pathReference = ref(
        //             storage,
        //             `gs://cim-project-7b81c.firebasestorage.app/pdfs/${companyName}/hd_page-${page}.png`
        //         );
        //         let currPage = parseInt(page);
        //         getDownloadURL(pathReference).then(url => {
        //             current[currPage] = url;
        //             preloadImages([url]);
        //         });
        //     }
        // });

        autorun(() => {
            if (!this.activeReport) {
                return null;
            }

            var el = document.getElementById('rootContainer');
            el.style.width = '2px';

            const storage = getStorage();
            var file = this.activeReport.pdf;
            const pathReference = ref(
                storage,
                `gs://cim-project-7b81c.firebasestorage.app/pdfs/${file}`
            );

            getDownloadURL(pathReference).then(url => {
                this.pdfFileUrl = url;
            });
        });
    }

    @observable accessor defaultContentSize = 50;

    @observable accessor lastReportImagesFetched: string = '';
    @observable accessor imageLookup: { [company: string]: ImageLookup } = {};

    @observable
    accessor modal: ModalDisplay = null;

    @observable
    accessor updatedStockInfo: StockInfo = null;

    @observable
    accessor waitingForTickerToFinish: string = '';

    @observable accessor dealPageNameSearch: string = '';
    @observable accessor dealPageIndustrySearch: string[] = [];
    @observable
    accessor uiSearchTicker: string = '';

    @observable
    accessor reportDataDB: any = null;

    @observable
    accessor settings: any = {};

    @computed get showExportButton() {
        return this.settings?.showExportButton ?? false;
    }

    @observable
    accessor reportDataOverridesDB: any = {};

    @observable
    accessor jobs: any = {};

    @observable
    accessor systemStatus: any = {};

    @observable accessor activeJob: ActiveJob = null;

    @computed get queueJobs() {
        return [];
        if (!this.jobs) {
            return [];
        }
        var activeJob = this.activeJob;

        var jobs = values(this.jobs);
        jobs = jobs.filter((job: any) => {
            return job.status == 'pending';
        });

        jobs = jobs.toSorted((a: any, b: any) => {
            return a.createdAt - b.createdAt;
        });

        return jobs;
    }

    @observable
    accessor pdfImagePage: number = -1;

    @observable accessor pdfFileUrl: string = '';

    @computed get processingJobName() {
        if (this.activeJob?.status != 'processing') {
            return '';
        }
        return this.activeJob?.ticker ?? '';
    }

    @computed get selectedTickerId() {
        return this.uiSearchTicker;
    }

    @observable accessor lastReportLoaded: string = '';

    @computed get reportOverride() {
        const ticker = this.selectedTickerId;
        var over_rides = {};
        if (
            this.reportDataOverridesDB &&
            this.reportDataOverridesDB.hasOwnProperty(ticker)
        ) {
            over_rides = this.reportDataOverridesDB[ticker];
        }
        return over_rides;
    }

    @computed get activeReportOriginal() {
        const ticker = this.selectedTickerId;
        if (!this.reportDataDB) {
            return null;
        }

        if (this.reportDataDB.hasOwnProperty(ticker)) {
            if (this.reportDataDB[ticker].status == 'processing') {
                return null;
            }

            if (this.lastReportLoaded != ticker) {
                runInAction(() => {
                    this.lastReportLoaded = ticker;
                    this.pdfImagePage = -1;
                });
            }

            //Override values
            const reportName = this.selectedTickerId;

            //const over_rides = System_Over_Overrides[reportName] || {};

            var over_rides = this.reportOverride;
            return _.cloneDeep(this.reportDataDB[ticker]);
        }

        return null;
    }

    @computed get activeReport() {
        const report = _.cloneDeep(this.activeReportOriginal);
        if (!report) {
            return null;
        }

        //Override values
        const reportName = this.selectedTickerId;

        var over_rides = this.reportOverride;
        for (var field in over_rides) {
            const value = over_rides[field];
            //typeof a == 'object' && Array.isArray(a)\
            //Connect to field type in schmea to know sub field
            const fieldsSchema = FieldsSchema;

            if (typeof value == 'object') {
                if (!Array.isArray(value)) {
                    var set = value as {};

                    if (!Array.isArray(report[field])) {
                        report[field] = [];
                    }

                    const keys = Object.keys(set);
                    keys.forEach(year => {
                        const yearValue = set[year];
                        assert(
                            fieldsSchema.hasOwnProperty(field),
                            `Field ${field} not found in schema`
                        );
                        const subFieldName = fieldsSchema[field].field;

                        const e = report[field].find(arrYear => {
                            return parseInt(arrYear.year) == parseInt(year);
                        });

                        const entry = {
                            year: parseInt(year),
                            [subFieldName]: yearValue
                        };

                        if (!e) {
                            report[field].push(entry);
                        } else {
                            e[subFieldName] = yearValue;
                        }
                    });
                } else {
                    //Array TODO
                }
            } else {
                report[field] = value;
            }
        }

        return report;
    }
    @computed get query(): DataAccessUtils {
        if (!this.activeReport) {
            return null;
        }

        return new DataAccessUtils(this.activeReport, this.reportOverride);
    }

    @computed
    get company_name() {
        return this.activeReport?.company_name;
    }

    @computed
    get ticker() {
        return this.activeReport?.ticker;
    }

    @computed get reportCreationDate() {
        if (!this.activeReport?.created) return null;

        return moment.unix(this.activeReport?.created).format('YYYY-MM-D');
    }

    @computed get errorMessageTicker() {
        if (!this.activeReport?.error) return null;
        return this.activeReport?.error;
    }
    @computed get reportFiscalYear() {
        if (!this.activeReport?.report_fiscal_year) return null;

        return this.activeReport?.report_fiscal_year;
    }

    @computed get companyOverview() {
        return this.activeReport?.company_overview;
    }
}

const store = new Store();

export const useStore = () => store;
export const useBackend = () => {
    return new Backend();
};
export default store;
