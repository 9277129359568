interface ChatMessageProps {
    isMe: boolean;
    message: string;
    timestamp: Date;
    // embedList: number[];
}
import { Flex } from '../UI/Flex/Flex';
import * as style from './ChatMessage.module.less';

import biz_man from './biz-man.svg';
import search from './search.svg';

const documents = require('./documents.csv');
const embed = require('./embedded.csv');

const embed_id_to_doc_id = (embed_id: number) => {
    const doc_id = embed.filter((e: any) => e[0] === embed_id.toString());

    if (doc_id.length === 0) {
        return -1;
    } else {
        return doc_id[0][1];
    }
};

const doc_id_to_doc_name = (doc_id: number) => {
    const doc_name = documents.filter((d: any) => d[0] === doc_id.toString());

    if (doc_name.length === 0) {
        return '';
    } else {
        return doc_name[0][1];
    }
};

export const ChatMessage = (p: ChatMessageProps) => {
    const files_references = null;

    // const files_references = (
    //     <Flex>
    //         {p.embedList.map((embed_id, index) => {
    //             const doc_id = embed_id_to_doc_id(embed_id);
    //             const doc_name = doc_id_to_doc_name(doc_id);

    //             return (
    //                 <div key={index} className="p-2" style={{ width: '200' }}>
    //                     <div>{doc_name}</div>
    //                 </div>
    //             );
    //         })}
    //     </Flex>
    // );

    if (p.isMe) {
        return (
            <>
                <Flex className={style.border} direction="row">
                    <div className="p-4" style={{ width: '100px' }}>
                        {p.timestamp.toLocaleTimeString().replace(/AM|PM/, '')}
                    </div>
                    <div
                        className="flex-grow-1 p-4 align-self-center"
                        style={{ textAlign: 'right' }}
                    >
                        {p.message}
                    </div>
                    <div className="p-4" style={{ height: '100px' }}>
                        <img
                            src={biz_man}
                            style={{ objectFit: 'contain', height: '100%' }}
                        />
                    </div>
                </Flex>
                {/* {files_references} */}
            </>
        );
    }

    return (
        <>
            <Flex direction="row" className={style.border}>
                <div className="p-3" style={{ height: '100px' }}>
                    <img
                        src={search}
                        style={{ objectFit: 'contain', height: '100%' }}
                    />
                </div>
                <div className="flex-grow-1 p-4 align-self-center">
                    {p.message}
                </div>
                <div className="p-4 align-self-end" style={{ width: '100px' }}>
                    {p.timestamp.toLocaleTimeString().replace(/AM|PM/, '')}
                </div>
            </Flex>
            {/* {files_references} */}
        </>
    );
};
