import { Flex } from '@assets/component/UI/Flex/Flex';
import * as style from './PanelCompanyOverview.module.less';
import { observer } from 'mobx-react';
import { useStore } from '@assets/model/Store';

export const PanelCompanyOverview = observer(() => {
    const store = useStore();
    return (
        <Flex direction="column">
            <div className={style.title}>Business Overview</div>
            <div className={style.desc}>{store.companyOverview}</div>
        </Flex>
    );
});
