import { Flex } from '../UI/Flex/Flex';

import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { DealContentHeader } from './DealContentHeader/DealContentHeader';
import * as style from './DealPageContent.module.less';
import { DealThumbnail } from './DealThumbnail/DealThumbnail';

export const DealPageContent = observer(() => {
    const store = useStore();

    useEffect(() => {
        store.dealPageIndustrySearch = [];
        store.dealPageNameSearch = '';
    }, []);

    if (!store.activeReport) {
        return null;
    }

    const dealKeys = Object.keys(store.reportDataDB);
    const filtered = dealKeys.filter(deal => {
        var e = store.reportDataDB[deal];
        const companyName = store.reportDataDB[deal].company_name;
        const matchCompanyName =
            companyName
                .toLowerCase()
                .includes(store.dealPageNameSearch.toLowerCase()) ||
            store.dealPageNameSearch == '';

        const filter = store.dealPageIndustrySearch;
        var industry = e.industry;
        var sub_industry = e.industrySubType;
        var matchIndustry =
            filter.includes(industry) ||
            filter.includes(sub_industry) ||
            filter.length == 0;

        return matchCompanyName && matchIndustry;
    });
    let deals = filtered.map(deal => {
        return <DealThumbnail name={deal} key={deal} />;
    });

    if (deals.length % 3 === 2) {
        deals.push(<div key="empty" style={{ width: '33%' }}></div>);
    }

    return (
        <>
            <Flex
                direction="column"
                fillCell={true}
                className={style.container}
            >
                <DealContentHeader />
                <Flex wrap="wrap" justify="start" alignItems="start">
                    {deals}
                </Flex>
            </Flex>
        </>
    );
});
