import { observer } from 'mobx-react';
import * as style from './MetadataEntry.module.less';

interface IMetadataEntryProps {
    label: string;
    value: string;
}
export const MetadataEntry = observer((p: IMetadataEntryProps) => {
    return (
        <div>
            <span className={style.title}> {p.label} </span>
            <span className={style.value}>{p.value}</span>
        </div>
    );
});
