.chatMessage {
    width: 100%;
    padding: 20px;
    min-height: 150px;
}

.border {
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
}
