import {
    PanelColumn,
    PanelRow,
    PanelTable
} from '@assets/component/Panel/PanelTable/PanelTable';
import { PanelTitle } from '@assets/component/Panel/PanelTitle/PanelTitle';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { RowLabel } from '../PanelElements/RowLable/RowLable';
import { RowValue } from '../PanelElements/RowValue/RowValue';
import {
    formatDollar,
    formatPercentNoMultiply
} from '../PanelElements/Formatters';
import { onEditReportField } from '../UpdateFieldsUtil';

export const PanelKeyMetrics = observer(() => {
    const { query, activeReport } = useStore();
    const data = activeReport;

    const columns: PanelColumn[] = [
        {
            column: {
                source: '',
                display: 'Value'
            }
        },
        {
            column: { source: null, display: 'Source' },
            element: RowValue.sources,

            dontUseRowFormatting: true,
            overrideValue: query.sources.bind(query)
        }
    ];

    const makeRow = (
        fieldName: string,
        display: string,
        formatter: (data) => string
    ) => {
        return {
            field: {
                source: fieldName,
                display: display
            },
            value: (_field, _column) => {
                return query.value(fieldName);
            },
            element: RowValue.value,
            label: RowLabel.label,
            formatter
        };
    };
    const rows: PanelRow[] = [
        {
            field: {
                source: 'recurring_revenue_summary',
                display: 'Contractual Revenue'
            },
            element: RowValue.boolean,
            value: (field, _column) => {
                return query.value(field.source);
            },
            label: RowLabel.label,
            editable: true
        },
        makeRow(
            'does_not_exist',
            'Payback Period for Growth Capex',
            formatDollar
        )
        // makeRow(
        //     'nwc_ratio_to_revenue',
        //     'NWC to Revenue',
        //     formatPercentNoMultiply
        // ),
        // makeRow('fcf_conversion', 'FCF Conversion', formatPercentNoMultiply)
    ];

    return (
        <>
            <PanelTitle title="Key Metrics" />
            <PanelTable
                columns={columns}
                rows={rows}
                onEditField={onEditReportField}
            />
        </>
    );
});
