[
    {
        "name": "Consumer/Retail",
        "id": 1,
        "subtypes": [
            {"name": "Apparel", "id": 1},
            {"name": "Beauty and Personal Care Products", "id": 2},
            {"name": "Convenience Stores", "id": 3},
            {"name": "Food and Beverage", "id": 4},
            {"name": "Department Stores", "id": 5},
            {"name": "Pet Food and Services", "id": 6},
            {"name": "Restaurants", "id": 7},
            {"name": "Specialty and Hardline Retailers", "id": 8},
            {"name": "Supermarkets", "id": 9},
            {"name": "Other Consumer/Retail", "id": 10}
        ]
    },
    {
        "name": "Financial Institutions",
        "id": 2,
        "subtypes": [
            {"name": "Banks", "id": 11},
            {"name": "Insurance Companies", "id": 12},
            {"name": "Asset Management Firms", "id": 13},
            {"name": "Financial Technology Companies", "id": 14},
            {"name": "Specialty Finance Institutions", "id": 15},
            {"name": "Other Financial Institutions", "id": 16}
        ]
    },
    {
        "name": "Healthcare",
        "id": 3,
        "subtypes": [
            {"name": "Biotechnology", "id": 17},
            {"name": "Diagnostics/Life Sciences", "id": 18},
            {"name": "Healthcare Information Technology", "id": 19},
            {"name": "Healthcare Services", "id": 20},
            {"name": "Medical Devices", "id": 21},
            {"name": "Pharmaceuticals", "id": 22},
            {"name": "Other Healthcare", "id": 23}
        ]
    },
    {
        "name": "Industrials",
        "id": 4,
        "subtypes": [
            {"name": "Aerospace and Defense", "id": 24},
            {"name": "Automotive", "id": 25},
            {"name": "Building and Construction", "id": 26},
            {"name": "Business Services", "id": 27},
            {"name": "Capital Goods", "id": 28},
            {"name": "Diversified Industrials", "id": 29},
            {"name": "Industrial Technology", "id": 30},
            {"name": "Paper", "id": 31},
            {"name": "Forest Products And Packaging", "id": 32},
            {"name": "Transportation And Logistics", "id": 33},
            {"name": "Other Industrials", "id": 34}
        ]
    },
    {
        "name": "Natural Resources",
        "id": 5,
        "subtypes": [
            {"name": "Energy", "id": 35},
            {"name": "Power", "id": 36},
            {"name": "Infrastructure", "id": 37},
            {"name": "Chemicals", "id": 38},
            {"name": "Metals and Mining", "id": 39},
            {"name": "Other Natural Resources", "id": 40}
        ]
    },
    {
        "name": "Public Sector and Infrastructure",
        "id": 6,
        "subtypes": [
            {"name": "State and Local Governments", "id": 41},
            {"name": "Not-For-Profit Healthcare Systems", "id": 42},
            {"name": "Higher Education Institutions", "id": 43},
            {"name": "Public Power Utilities", "id": 44},
            {"name": "Surface Transportation and Mass Transit Agencies", "id": 45},
            {"name": "Airports and Seaports", "id": 46},
            {"name": "Sports Franchises", "id": 47},
            {"name": "Other Public Sector and Infrastructure", "id": 48}
        ]
    },
    {
        "name": "Real Estate",
        "id": 7,
        "subtypes": [
            {"name": "Real Estate Investment Trusts (REITs)", "id": 49},
            {"name": "Hotels and Resorts", "id": 50},
            {"name": "Retailers and Public Sector Entities", "id": 51},
            {"name": "Retail Properties", "id": 52},
            {"name": "Golf Courses", "id": 53},
            {"name": "Shopping Centers", "id": 54},
            {"name": "Office Buildings", "id": 55},
            {"name": "Industrial Properties", "id": 56},
            {"name": "Multifamily Properties", "id": 57},
            {"name": "Development Projects", "id": 58},
            {"name": "Other Real Estate", "id": 59}
        ]
    },
    {
        "name": "Technology, Media, and Telecommunications",
        "id": 8,
        "subtypes": [
            {"name": "Software", "id": 60},
            {"name": "Internet", "id": 61},
            {"name": "Semiconductors", "id": 62},
            {"name": "Hardware", "id": 63},
            {"name": "Communications Technology", "id": 64},
            {"name": "Information Services", "id": 65},
            {"name": "Education Technology", "id": 66},
            {"name": "Media", "id": 67},
            {"name": "Telecom", "id": 68},
            {"name": "Gaming Companies", "id": 69},
            {"name": "Other Technology, Media, and Telecommunications", "id": 70}
        ]
    }
]
