import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { i18n } from '../model/Translation';
import axios from 'axios';
import { useChatStore } from '@assets/model/StoreChat';
import { ChatMessage } from '@assets/component/ChatMessage/ChatMessage';
import { DEFAULT_LABELKEY } from 'react-bootstrap-typeahead/types/constants';

const { t } = i18n;

const ChatUI = observer(() => {
    const chatMessages = useChatStore();

    const { id } = useParams();
    const store = useStore();
    useEffect(() => {
        if (id) {
            store.uiSearchTicker = id;
        }
    }, [id]);
    return (
        <>
            <div
                className="d-flex justify-content-center flex-column"
                style={{ height: '100%' }}
            >
                <Flex
                    id="chatContainer"
                    direction="column"
                    style={{
                        width: '100%',
                        overflowY: 'scroll',
                        height: '90%'
                    }}
                >
                    {chatMessages.chatMessages.map((message, index) => {
                        return (
                            <ChatMessage
                                key={index}
                                isMe={message.isMe}
                                message={message.message}
                                timestamp={message.time}
                                embedList={message.embedList}
                            />
                        );
                    })}
                </Flex>

                <Flex direction="row">
                    <div className="form-group" style={{ width: '90%' }}>
                        <textarea
                            className="form-control"
                            id="textInput"
                            rows={2}
                        ></textarea>
                    </div>
                    <Button
                        style={{ width: '10%' }}
                        onClick={async () => {
                            const el = document.getElementById('textInput');
                            const val = el.value;

                            chatMessages.chatMessages.push({
                                isMe: true,
                                message: val,
                                time: new Date()
                            });

                            const scroll_end = () => {
                                setTimeout(() => {
                                    const cont =
                                        document.getElementById(
                                            'chatContainer'
                                        );
                                    const chatContainer =
                                        cont as HTMLDivElement;
                                    if (chatContainer)
                                        chatContainer.scrollTop =
                                            chatContainer.scrollHeight;
                                }, 100);
                            };
                            el.value = '';
                            scroll_end();

                            let url =
                                'https://and-dev-nixaboo.ngrok.dev/ai/get_answer_using_rag?api_key=abc';

                            // url =
                            //     'http://localhost/ai/get_answer_using_rag?api_key=abc';

                            try {
                                var ret = await axios.post(url, {
                                    prompt: val,
                                    doc_ids: [
                                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
                                        13, 14
                                    ],
                                    numb_of_results: 10
                                });

                                chatMessages.chatMessages.push({
                                    isMe: false,
                                    message: ret.data.data.response,
                                    time: new Date()
                                    // embedList: ret.data.data.embedding_id ?? []
                                });

                                scroll_end();
                            } catch (e) {
                                console.log(e);
                            }
                        }}
                    >
                        Send
                    </Button>
                </Flex>
            </div>
        </>
    );
});

export default withRouter(ChatUI);
