import { Flex } from '@assets/component/UI/Flex/Flex';
import { observer } from 'mobx-react';
import * as style from './ScoreBox.module.less';

//<i class="bi bi-x-circle-fill"></i>
//<i class="bi bi-check-circle-fill"></i>

interface IScoreBoxProps {
    name: string;
    rating: string;
}

export const ScoreBox = observer((props: IScoreBoxProps) => {
    return (
        <Flex className="flex-grow-1">
            <div className="m-3">
                <i className="bi bi-x-circle-fill fs-2"></i>
            </div>
            <div className="flex-grow-1 m-3">
                <Flex direction="column">
                    <div>{props.name}</div>
                    <div>{props.rating}</div>
                </Flex>
            </div>
        </Flex>
    );
});
