@import '../../../utils/global.module.less';

.panelRowHeader {
    font-family: LustTextMedium;
    font-style: italic;
    font-weight: 300;
    padding: @title-padding;
    border-radius: 20px 20px 0 0;
    background-color: @title-color;
    font-size: 20px;
    color: white;
    > i {
        color: #cac7b7;
    }

    > * {
        padding: 0 5px;
    }
}
