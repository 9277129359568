import { observer } from 'mobx-react';
import { ScoreBox } from '../../ScoreBox/ScoreBox';
import { Flex } from '@assets/component/UI/Flex/Flex';
import * as style from './ScoreSummary.module.less';

export const ScoreSummary = observer(() => {
    const rating = [
        'Overall Score',
        'Business Score',
        'Financial Score',
        'Industry Score'
    ].map(i => {
        return <ScoreBox key={i} name={i} rating="N/A" />;
    });

    return <Flex className={style.container}>{rating}</Flex>;
});
