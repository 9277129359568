import {
    Field,
    PanelColumn,
    PanelRow,
    PanelTable
} from '@assets/component/Panel/PanelTable/PanelTable';
import { PanelTitle } from '@assets/component/Panel/PanelTitle/PanelTitle';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { formatDollarStr, formatPercentStr } from '@assets/utils/Formatting';
import { observer } from 'mobx-react';
import * as style from './PanelFinanceYearly.module.less';
import { RowLabel } from '../PanelElements/RowLable/RowLable';
import { RowValue } from '../PanelElements/RowValue/RowValue';
import { DataAccessUtils } from '../../DataAccessUtils';
import {
    formatDollar,
    formatPercent,
    formatPercentNoMultiply
} from '../PanelElements/Formatters';
import { FieldsSchema } from '@assets/model/FieldsSchema';
import { System_Over_Overrides } from '@assets/model/override_file';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { onEditReportField } from '../UpdateFieldsUtil';

export const PanelFinanceYearly = observer(() => {
    const store = useStore();
    const query = store.query;
    const data = store.activeReport;

    const findByYear = (yearOffset: number = 0) => {
        return (field: Field, column: Field) => {
            const year = parseInt(column.source) + yearOffset;
            return query.valueByYear(field.source, year);
        };
    };

    const findByYearDivideByRevenue = (yearOffset: number = 0) => {
        return (field: Field, column: Field) => {
            const year = parseInt(column.source) + yearOffset;
            const fieldValue = query.valueByYear(field.source, year);
            const revenue = query.valueByYear('net_revenue_per_year', year);
            if (fieldValue === 'N/A' || revenue === 'N/A') {
                return 'N/A';
            }

            return fieldValue / revenue;
        };
    };

    const createColumnsYears = (
        fromYear: number,
        toYear: number
    ): PanelColumn[] => {
        const actualizedYears = data['list_years_actualized_revenue'] ?? [];
        const minActualizedYear = Math.min(...actualizedYears);

        const cols: PanelColumn[] = [];
        for (let year = fromYear; year <= toYear; year++) {
            const isActualized =
                actualizedYears.includes(year) || year <= minActualizedYear;
            const letter = isActualized ? 'A' : 'P';

            cols.push({
                column: {
                    source: year.toString(),
                    display: year.toString() + letter
                }
            });
        }

        return cols;
    };

    const getReportYearRange = () => {
        if (!data) {
            return { from: 0, to: 0 };
        }

        var minYear = -1;
        var maxYear = -1;
        const fields = [
            'adjusted_ebitda_per_year',
            'net_revenue_per_year',
            'ebitda_per_year'
        ];

        fields.forEach(field => {
            const list = data[field];
            if (!list || Array.isArray(list) === false) {
                return;
            }
            const years = list.map((item: any) => parseInt(item.year));
            const min = Math.min(...years);
            const max = Math.max(...years);
            if (minYear == -1 || min < minYear) {
                minYear = min;
            }
            if (maxYear == -1 || max > maxYear) {
                maxYear = max;
            }
        });

        const displayLimit = 7;
        if (maxYear - minYear > displayLimit) {
            const trim = maxYear - minYear - displayLimit;
            return { from: minYear + trim, to: maxYear };
        }
        return { from: minYear, to: maxYear };
    };

    const tableRange = getReportYearRange();

    const columns: PanelColumn[] = [
        ...createColumnsYears(tableRange.from, tableRange.to),
        {
            column: { source: null, display: 'Source' },
            element: RowValue.sources,
            dontUseRowFormatting: true,
            overrideValue: query.sources.bind(query)
        }
    ];

    const defaults = {
        label: RowLabel.label,
        formatter: formatDollar,
        element: RowValue.value,
        editable: true
    };

    const defaultsPercent = {
        label: RowLabel.percent_label,
        formatter: formatPercent,
        element: RowValue.percent,
        dontShowSources: true
    };

    const rows: PanelRow[] = [
        {
            ...defaults,
            field: {
                source: 'net_revenue_per_year',
                display: 'Revenue'
            },
            value: findByYear()
        },

        {
            ...defaultsPercent,
            field: {
                source: 'net_revenue_per_year',
                display: 'Revenue Growth'
            },
            value: (c, f) => {
                const thisYear = findByYear()(c, f);
                const lastYear = findByYear(-1)(c, f);
                if (thisYear === 'N/A' || lastYear === 'N/A') {
                    return 'N/A';
                }

                const growth = (thisYear - lastYear) / lastYear;
                return growth;
            }
        },
        {
            ...defaults,
            field: {
                source: 'gross_profit_per_year',
                display: 'Gross Profit'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: 'gross_profit_per_year',
                display: 'Gross Margin'
            },
            value: findByYearDivideByRevenue()
        },
        {
            ...defaults,
            field: {
                source: 'ebitda_per_year',
                display: 'EBITDA'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: 'ebitda_per_year',
                display: 'EBITDA Margin'
            },
            value: (c, f) => {
                const ebitda = query.valueByYear(
                    'ebitda_per_year',
                    parseInt(f.source)
                );
                const revenue = query.valueByYear(
                    'net_revenue_per_year',
                    parseInt(f.source)
                );
                if (ebitda === 'N/A' || revenue === 'N/A') {
                    return 'N/A';
                }

                return ebitda / revenue;
            }
        },
        {
            ...defaults,
            field: {
                source: 'adjusted_ebitda_per_year',
                display: 'Adj. EBITDA'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: 'adjusted_ebitda_per_year',
                display: 'Adj. EBITDA Margin'
            },
            value: (c, f) => {
                const ebitda = query.valueByYear(
                    'adjusted_ebitda_per_year',
                    parseInt(f.source)
                );
                const revenue = query.valueByYear(
                    'net_revenue_per_year',
                    parseInt(f.source)
                );
                if (ebitda === 'N/A' || revenue === 'N/A') {
                    return 'N/A';
                }

                return ebitda / revenue;
            }
        },
        {
            ...defaultsPercent,
            editable: true,
            field: {
                source: 'gross_retention_lost_only_per_year',
                display: 'Gross Retention (Lost Only)'
            },
            value: findByYear(),
            formatter: formatPercentNoMultiply
        },
        {
            ...defaultsPercent,
            editable: true,
            field: {
                source: 'net_retention_yearly',
                display: 'Net Retention'
            },
            value: findByYear(),
            formatter: formatPercentNoMultiply
        },
        {
            ...defaults,
            field: {
                source: 'total_current_assets_yearly',
                display: 'Current Assets'
            },
            value: findByYear()
        },
        {
            ...defaults,
            field: {
                source: 'total_current_liabilities_yearly',
                display: 'Current Liabilities'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: null,
                display: 'NWC Ratio, % of Revenue'
            },
            value: (f, c) => {
                const year = parseInt(c.source);
                const assets = query.valueByYear(
                    'total_current_assets_yearly',
                    year
                );

                const Liabilities = query.valueByYear(
                    'total_current_liabilities_yearly',
                    year
                );

                const revenue = query.valueByYear('net_revenue_per_year', year);
                if (
                    assets === 'N/A' ||
                    Liabilities === 'N/A' ||
                    revenue === 'N/A'
                ) {
                    return 'N/A';
                }

                return (assets - Liabilities) / revenue;
            }
        },
        {
            ...defaults,
            field: {
                source: 'capex_per_year',
                display: 'Capex'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: 'capex_per_year',
                display: 'Capex, % of Revenue'
            },
            value: findByYearDivideByRevenue()
        },
        {
            ...defaults,
            field: {
                source: 'ebitda_cashflow_yearly',
                display: 'Free Cash Flow (FCF)'
            },
            value: findByYear()
        },
        {
            ...defaultsPercent,
            field: {
                source: null,
                display: 'FCF Conversion, % of EBITDA'
            },
            value: (f, c) => {
                const year = parseInt(c.source);
                const ebitda = query.valueByYear(
                    'ebitda_cashflow_yearly',
                    year
                );

                const capex = query.valueByYear('capex_per_year', year);

                if (ebitda === 'N/A' || capex === 'N/A') {
                    return 'N/A';
                }

                return (ebitda - capex) / ebitda;
            }
        }
    ];

    return (
        <>
            <PanelTitle title="General" />
            <PanelTable
                columns={columns}
                rows={rows}
                disable_round_edges={true}
                onEditField={onEditReportField}
            />
        </>
    );
});
