import { Flex } from '@assets/component/UI/Flex/Flex';
import * as style from './PanelHeader.module.less';
export const PanelHeader = (props: { title: string }) => {
    return (
        <Flex className={style.panelRowHeader}>
            <i className="bi bi-clipboard"></i>
            <div className="flex-grow-1">{props.title}</div>
            <div>Score: N/A</div>
        </Flex>
    );
};
